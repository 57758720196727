<template>
  <Layout id="app" class="h-screen">
    <Content class="h-full center">
      <Poker />
    </Content>
  </Layout>
</template>

<script>

import Poker from "./components/Poker";
export default {
  name: `app`,
  components: {
    Poker,
  },
};
</script>

<style>
#app {
  @apply text-gray-50 !important;
  background-color: #37393f !important;
}
</style>
