<template>
  <div class="mx-auto w-1/2">
    <Score :displayScores="displayScores" class="mb-3 m-2" />
<!--    <Row>-->
<!--        Temps : <Button v-for="number of timeNumbers" :key="number" :disabled="displayScores" @click="setScore(`time`, number)" :type="time === number ? `primary` : `default`">{{number}}</Button>-->
<!--    </Row>-->
    <Row class="mb-3 ml-2">
        Valeur : <Button v-for="number of difficultyNumbers" :key="number" :disabled="displayScores" @click="setScore(`difficulty`, number)" :class="difficulty === number ? `selected` : ``">{{number}}</Button>
    </Row>
    <Row class="ml-2">
      <Button @click="setNameModal = true">Changer de nom</Button>
      <template v-if="name === `admin`">
        <Button @click="startVote">Démarrer un nouveau vote</Button>
        <Button @click="showScores">Dévoiler les scores</Button>
      </template>
    </Row>
    <Modal
        v-model="setNameModal"
        title="Saisissez votre nom"
        @on-ok="setName"
        @on-cancel="setNameModal = false">
      <Input v-model="name" placeholder="Votre nom" />
    </Modal>
  </div>
</template>

<script>
import Score from './Score.vue';

export default {
  name: `Poker`,
  components: {
    Score,
  },
  data: () => ({
    setNameModal: false,
    users: [],
    name: ``,
    difficultyNumbers: [`0`, `1`, `2`, `3`, `5`, `8`, `13`, `20`, `40`, `100`, `?`, `∞`, `☕`],
    timeNumbers: [`0.5`, `1`, `1.5`, `2`, `2.5`, `3`, `5`, `8`, `13`, `20`, `40`, `100`],
    displayScores: true,
    time: null,
    difficulty: null,
  }),
  methods: {
    startVote() {
      this.$socket.client.emit(`startVote`);
    },
    showScores() {
      this.$socket.client.emit(`setDisplayScores`, true);
    },
    setScore(key, value) {
      this[key] = value;
      this.$socket.client.emit(`setScore`, key, value);
    },
    setName() {
      this.$socket.client.emit(`setName`, this.name);
      localStorage.setItem(`name`, this.name);
      this.setNameModal = false;
    },
  },
  sockets: {
    cleanScores() {
      this.time = null;
      this.difficulty = null;
    },
    setDisplayScores(displayScores) {
      this.displayScores = displayScores;
    },
    setName() {
      const name = localStorage.getItem(`name`);
      if (name) {
        this.name = name;
        this.setName();
      } else {
        this.setNameModal = true;
      }
    },
  },
};
</script>

<style scoped>
.ivu-btn {
  @apply text-gray-300 border-gray-800 !important;
  background-color: #2f3136 !important;
}
.ivu-btn:disabled {
  @apply text-gray-500 bg-gray-900 border-gray-800 !important;
}
.ivu-btn:disabled {
  @apply text-gray-500 bg-gray-900 border-gray-800 !important;
}

.selected {
  background-color: #5563e9 !important;
}
</style>
